import React from 'react';
import cn from 'classnames';
import _ from 'lodash';
import { isMobileOrTablet } from 'client/hooks/responsive';
import { eventsAPI } from 'client/redux/api/events';

import { Filter } from 'client/components/common/Filter';
import { initialState as initialFilterState } from 'client/components/common/Filter/reducer';
import { FiltersState } from 'client/components/common/Filter/reducer';

import css from './Iframe.module.scss';

interface Props {
  className?: string;
}

const Iframe: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const [isFirstClickOnSearch, setIsFirstClickOnSearch] = React.useState(true);
  const [trigger] = eventsAPI.endpoints.getEventsList.useLazyQuery();
  const isRealMobile = isMobileOrTablet();

  React.useEffect(() => {
    trigger({ ...initialFilterState.date, ..._.omit(initialFilterState, 'date') });
  }, []);

  const appRef = React.useRef<HTMLElement | null>(null);

  const observer = React.useCallback(() => {
    const height = appRef.current?.offsetHeight || 0;

    const parentHeight = window.innerHeight;
    if (height !== parentHeight && Math.abs(height - parentHeight) > 5) {
      window.parent.postMessage(
        {
          payload: { iframeHeight: height },
          sender: 'WAR_TIME_ACTIVITIES_WIDGET',
          type: 'resize',
        },
        '*',
      );
    }
    requestAnimationFrame(observer);
  }, []);

  React.useEffect(() => {
    if (!appRef.current) {
      appRef.current = document.getElementById('root');
    }
    const rafId = requestAnimationFrame(observer);

    return () => {
      cancelAnimationFrame(rafId);
    };
  }, [observer]);

  const onSearchClick = (params: FiltersState) => {
    if (_.isEqual(params, initialFilterState)) {
      return;
    }

    if (isFirstClickOnSearch) {
      setIsFirstClickOnSearch(false);
    }
    window.open(
      `${window.location.origin}/?${isRealMobile ? 'externalurl=true&' : ''}age=${params.age}&area=${
        params.area
      }&eventType=${params.eventType}&isOnlyFreeEvents=${params.isOnlyFreeEvents}&search=${params.search}&fromDate=${
        params.date.fromDate ? params.date.fromDate : ''
      }&toDate=${params.date.toDate ? params.date.toDate : ''}`,
      '_blank',
    );
  };

  return (
    <div className={cn(css.iframe, className, isRealMobile && css.isRealMobile)}>
      <Filter onSearchBtnClick={onSearchClick} isIframe className={css.isIframeFilter} />
    </div>
  );
};

export default Iframe;
