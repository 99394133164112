import React, { FC, useState } from 'react';
import { useAppSelector, useResponsive, useTranslation } from 'client/hooks';
import _ from 'lodash';
import cn from 'classnames';
import { format, parseISO } from 'date-fns';
import { dataLayerEvents } from 'utils/gtag';

import { Event } from 'models/index';
import { selectSettings } from 'client/redux/settings/selectors';

import Icon from '../../Icon';

import css from './EventItem.module.scss';

interface Props {
  event: Event.Model;
}

export const EventItem: FC<Props> = (props): JSX.Element => {
  const { event } = props;
  const [isFullHeight, setIsFullHeight] = useState<boolean>(false);
  const [isMatchingMQ] = useResponsive(['MOBILE', 'TABLET']);
  const { translate } = useTranslation('general');
  const settings = useAppSelector(selectSettings);
  const { general, design } = settings;

  const renderLocation = () => {
    return (
      event.location && (
        <div className={css.informationItem}>
          <div className={css.informationIcon}>
            <Icon color={settings.design.iconsColor} type="location" />
          </div>
          <div className={css.informationText}>{event.location}</div>
        </div>
      )
    );
  };
  const renderAge = () => {
    return (
      event.age && (
        <div className={css.informationItem}>
          <div className={css.informationIcon}>
            <Icon color={settings.design.iconsColor} type="age" />
          </div>
          <div className={css.informationText}>
            {_.map(event.age, (a, index) => (
              <span key={`age-item-${index}`}>{`${general.age[+a]} ${_.size(event.age) - 1 > index ? ',' : ''}`}</span>
            ))}
          </div>
        </div>
      )
    );
  };
  const renderDates = () => {
    return (
      !_.isEmpty(event.dates) && (
        <div className={css.informationItem}>
          <div className={css.informationIcon}>
            <Icon color={settings.design.iconsColor} type="date" />
          </div>
          <div className={css.informationText}>
            {_.map(event.dates, (item, index) => {
              return (
                <span key={item.date}>
                  {item.from === item.to
                    ? `${format(parseISO(item.from), 'dd/MM/yyyy')} ${item.additionalText}`
                    : `${format(parseISO(item.from), 'dd/MM/yyyy')} - ${format(parseISO(item.to), 'dd/MM/yyyy')} ${
                        item.additionalText
                      }`}
                  {_.size(event.dates) - 1 > index && ' ,'}
                </span>
              );
            })}
          </div>
        </div>
      )
    );
  };
  const renderTags = () => {
    return (
      <div className={css.tags}>
        {event.isFunded && (
          <div className={css.tag} style={{ backgroundColor: '#fff' }}>
            {translate('fundedTag')}
          </div>
        )}
        {event.isFree && (
          <div className={css.tag} style={{ backgroundColor: design.borderColor }}>
            {translate('freeTag')}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={cn(css.container, isFullHeight && css.isFullHeight, event.isFunded && css.isFundedEvent)}
      style={event.isFunded ? { border: 'none' } : { borderColor: design.borderColor }}
    >
      <div className={css.imgWrapper}>
        <img src={event.imageUrl} alt={event.title} />
        {event.credits && <p className={css.credits}>{event.credits}</p>}
      </div>

      <div className={css.containerEventData}>
        <div className={css.texts}>
          <div className={css.title}>{event.title}</div>
          <div className={css.description}>{event.description}</div>
        </div>
        <div className={css.information}>
          {renderLocation()}
          {renderAge()}
          {renderDates()}
          {event.externalLink && event.externalLinkLabel && (
            <a
              href={event.externalLink}
              rel="noreferrer"
              target="_blank"
              className={css.btnExternal}
              onClick={() => dataLayerEvents.onExternalLinkClick(event.title, event.externalLinkLabel || '')}
            >
              <div>{event.externalLinkLabel}</div>
            </a>
          )}
        </div>
      </div>
      {isMatchingMQ && (
        <div className={cn(css.togglerHandler, event.isFunded && css.isFundedStyles)}>
          <Icon
            color={settings.design.iconsColor}
            type="openArrow"
            className={css.arrowIcon}
            onClick={() => setIsFullHeight(!isFullHeight)}
          />
        </div>
      )}

      {renderTags()}
    </div>
  );
};
